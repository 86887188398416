var menuToggle = document.querySelector('.menu-toggle');
  
menuToggle.addEventListener('click', function() {
    menuToggle.classList.toggle('active');

    document.body.dataset.nav = document.body.dataset.nav === 'true' ? 'false' : 'true';
    document.body.setAttribute('data-navsel', 'null');
});

const m1 = document.querySelector('#main-nopt1');
const m2 = document.querySelector('#main-nopt2');
const m3 = document.querySelector('#main-nopt3');
const m4 = document.querySelector('#main-nopt4');
const m5 = document.querySelector('#main-nopt5');

document.getElementById('nav-event').addEventListener('mouseover', function() {
    document.body.setAttribute('data-navsel', 'null');
});

document.getElementById('main-nopt1').addEventListener('mouseover', function() {
    document.body.setAttribute('data-navsel', 'home');
});

document.getElementById('main-nopt2').addEventListener('mouseover', function() {
    document.body.setAttribute('data-navsel', 'solutions');
});

document.getElementById('main-nopt3').addEventListener('mouseover', function() {
    document.body.setAttribute('data-navsel', 'guides');
});

document.getElementById('main-nopt4').addEventListener('mouseover', function() {
    document.body.setAttribute('data-navsel', 'community');
});

document.getElementById('main-nopt5').addEventListener('mouseover', function() {
    document.body.setAttribute('data-navsel', 'resources');
});